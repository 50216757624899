<template>
  <div id="mobile">
    <div class="top-bar">
      <img src="@/assets/logo.png" alt="logo" class="logo">
      <div class="btn-search" @click="showSearch = true"></div>
    </div>
    <div class="app-container">
      <div class="lucky-grid">
        <img :src="require('../assets/pc/blocks.png')" class="lucky-img" />
        <LuckyGrid
          ref="LuckDraw"
          :width="luckyWidth"
          :height="luckyHeight"
          rows="6"
          cols="5"
          :blocks="blocks"
          :prizes="prizes"
          :buttons="buttons"
          :default-config="defaultConfig"
          :default-style="defaultStyle"
          :active-style="activeStyle"
          @start="startCallBack"
          @end="endCallBack"
        />
        <div class="lucky-btn" @click="startCallBack" :class="animation ? 'animation' : ''">
          <div class="lcy-btn-inner">开始抽奖</div>
        </div>
      </div>
      <div class="main-content">
        <div class="time-down" v-if="timeDownShow">
          <div class="sqa-l"></div>
          <div class="sqa-r"></div>
          <count-down tipText="距离活动开始倒计时" tipTextEnd="距离活动结束倒计时" :start-time="defaultTime.start_time" :end-time="defaultTime.end_time" @start_callback="countDownS_cb()" @end_callback="countDownE_cb()"></count-down>
        </div>
      </div>
      <div class="static-box" v-if="showLuckNum">
        <h3>欢迎登录</h3>
        <p>会员账号：{{username}}</p>
        <p>剩余抽奖次数：{{luckyNum}}</p>
      </div>
      <!-- <p class="copyright">{{iniData.cright}}</p> -->
    </div>
    <div class="bottom-btn-bar">
      <a :href="iniData.zuolist.nv1" target="_blank" class="bar-item">
        <span class="bar-icon bar-icon-home"></span>
        <span class="txt">首页</span>
      </a>
      <a :href="iniData.zuolist.nv2" target="_blank" class="bar-item">
        <span class="bar-icon bar-icon-app"></span>
        <span class="txt">APP下载</span>
      </a>
      <a :href="iniData.zuolist.nv3" target="_blank" class="bar-item">
        <span class="bar-icon bar-icon-gift"></span>
        <span class="txt">优惠</span>
      </a>
      <a :href="iniData.zuolist.nv4" target="_blank" class="bar-item">
        <span class="bar-icon bar-icon-service"></span>
        <span class="txt">客服</span>
      </a>
    </div>
    <div class="slide-float" @click="detailsVisible = true">
    </div>
    <van-popup
      v-model="userPopShow"
      closeable
      :style="{ width: '80%', height: '54vw' }"
      @open="username = ''"
      class="popup-user-block"
    >
      <div class="account-container">
        <img src="@/assets/logo.png" />
        <h3 class="title-bar">登录会员账号</h3>
        <div class="field__value-screen">
          <input type="text" placeholder="账号" v-model="username" class="field__control-screen" ref="ipt" @focus="$event.target.select()" />
          <button class="button--info-screen" @click="confirm">立即登录</button>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showSearch"
      closeable
      :style="{ width: '80%', height: '84vw' }"
      @close="closeSearchPopup"
      class="popup-search-block">
      <div class="block--title">输入会员账号查询</div>
      <div class="mcain-content">
        <div class="search-content">
          <div class="s-c-i">
            <span class="x-t">会员账号：</span>
            <input type="text" placeholder="账号" v-model="queryname" class="field__control" />
          </div>
          <div class="s-c-b">
            <button class="button--info" @click="queryResl">查询</button>
          </div>
        </div>
        <div class="result-content">
          <ul class="rc-head">
            <li>奖品名称</li>
            <li>领取时间</li>
            <li>是否派彩</li>
          </ul>
          <div class="rc-main" v-if="resl.length">
            <ul class="rc-body" v-for="(item, index) in resl" :key="index">
              <li>{{item.item_name}}</li>
              <li>{{item.used_time}}</li>
              <li>{{item.distribute_status === 1 ? '是' : '否'}}</li>
            </ul>
          </div>
          <div v-else style="font-size: 3.47vw;color: #fff;text-align: center;height: 7.2vw;line-height: 7.2vw;">暂无记录！</div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="detailsVisible"
      :style="{ width: '90%' }"
      class="details-popup"
    >
      <div class="title">
        <span class="x-close" @click="detailsVisible = false"></span>
      </div>
      <div class="content">
        <span class="details-trispan tlt"></span>
        <span class="details-trispan trt"></span>
        <span class="details-trispan tlb"></span>
        <span class="details-trispan trb"></span>
        <div class="neirong" v-html="iniData.mactcontent">
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showCoupons"
      closeable
      :style="{ width: '90%', height: '80vw' }"
      @close="closeCouponsPopup"
      class="popup-coupons-block">
      <div class="coupons-word">{{couponsWord}}</div>
    </van-popup>
  </div>
</template>

<script>
import CountDown from 'vue2-countdown'

export default {
  name: 'App',
  components: {
    CountDown
  },
  data () {
    return {
      showSearch: false,
      resl: [],
      defaultTime: {
        c_time: '',
        start_time: '',
        end_time: ''
      },
      iniData: {
        news: '',
        pcactcontent: '',
        pcxzcontent: '',
        pcyhcontent: '',
        mactcontent: '',
        cright:'',
        zuolist: {
          nv1: '#',
          nv2: '#',
          nv3: '#',
          nv4: '#',
        }
      },
      username: '',
      queryname: '',
      timeDownShow: false,
      hasTime: false,
      baseUrl: 'https://zzl.ee',
      listData: [],
      isReady: false,
      luckyNum: 0,
      blocks: [
        { padding: '20', background: 'rgba(0, 0, 0, 0)' },
      ],
      prizes: [],
      defaultConfig: {
        gutter: 5,
      },
      defaultStyle: {
        fontColor: '#9b1f35',
        fontSize: '10px',
        lineHeight: '10px',
        wordWrap: false,
        borderRadius: 5
      },
      activeStyle: {
        fontSize: '10px',
        fontColor: '#9b1f35',
        fontWeight: 'weight',
        background: 'rgba(255, 224, 73, .7)'
      },
      luckyWidth: document.documentElement.clientWidth,
      luckyHeight: document.documentElement.clientWidth / 1200 * 1050,
      userPopShow: false,
      showLuckNum: false,
      detailsVisible: false,
      animation: false,
      showCoupons: false,
      couponsWord: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    window.document.body.style.cssText = 'height: 100%;background: #fff;';
    window.document.documentElement.style.cssText = 'height: 100%;font-size: 3.2vw;'
    next()
  },
  beforeRouteLeave (to, from, next) {
    window.document.body.style.cssText = ''
    window.document.documentElement.style.cssText = ''
    next()
  },  
  created: function (){
    this.initDefaultData();
  },
  computed: {
    buttons () {
      return [{
        x: 1,
        y: 1,
        col: 3,
        row: 4,
        background: 'rgba(0, 0, 0, 0)',
        fonts: [
          // { text: `${this.showLuckNum ? `剩余次数：${this.luckyNum} 次` : ''}`, fontColor: '#fff', top: '89%', fontSize: '10px' },
        ],
        imgs: [
          // { src: require('../assets/buttons.png'), width: '95%', height: '80%', top: '10%' }
        ]
      }]
    }
  },
  methods: {
    getPrizeList () {
      var self = this
      return new Promise(resolve => {
        this.axios({
          method: 'post',
          url: self.baseUrl + '/index/Index/getitems'
        }).then(res => {
          resolve(res.data.data.list)
        })
      })
    },
    startCallBack () {
      var self = this
      if (!this.hasTime) {
        this.$toast.fail('活动尚未开始')
        return
      }
      if (!this.luckyNum) {
        if (this.username) {
          return this.$dialog.confirm({
            message: '抽奖机会已被用光，是否为其他账号抽奖！',
            confirmButtonText: '是',
            cancelButtonText: '否'
          }).then(() => {
            this.userPopShow = true
            this.username = '';
          }).catch(() => {
            
          })
        } else {
          return this.userPopShow = true
        }
      }
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      })
      this.animation = true
      this.axios({
        method: 'post',
        url: self.baseUrl + '/index/Index/getpacket',
        data: {
          username: self.username
        }
      }).then(res => {
        self.$toast.clear()
        var data = res.data
        if (data.code != 0) return this.$toast.fail(data.msg)
        this.$refs.LuckDraw.play()
        var giftId = data.data.itemid - 1
        self.$refs.LuckDraw.stop(giftId)
        self.luckyNum = data.data.times
      })
    },
    endCallBack (prize) {
      this.animation = false
      this.showCoupons = true
      // this.$dialog.alert({
      //   message: `恭喜你获得大奖: ${prize.fonts[0].text}`
      // })
      this.couponsWord = `恭喜你获得大奖: ${prize.fonts[0].text}`
    },
    closeCouponsPopup () {
      this.couponsWord = ''
    },
    initDefaultData () {
      var self = this;
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      Promise.all([
        self.getPrizeList(),
        self.initIndexData(),
        self.initTimeData()
      ]).then(([prizeList, indexList, timeData]) => {
        self.$toast.clear()
        const data = []
        // prizeList = prizeList.concat(prizeList.slice(0, 2)) // 需要注释掉
        prizeList.forEach(item => {
          data.push({
            name: item.name,
            img: self.baseUrl + item.pic
          })
        })
        const prizes = []
        let axis = [[0, 0], [1, 0], [2, 0], [3, 0], [4, 0], [4, 1], [4, 2], [4, 3], [4, 4], [4, 5], [3, 5], [2, 5], [1, 5], [0, 5], [0, 4], [0, 3], [0, 2], [0, 1]] // 5 * 5 格子
        // let axis = [[0, 0], [1, 0], [2, 0], [3, 0], [3, 1], [3, 2], [3, 3], [2, 3], [1, 3], [0, 3], [0, 2], [0, 1]] // 4 * 4 格子
        // let axis = [[0, 0], [1, 0], [2, 0], [2, 1], [2, 2], [1, 2], [0, 2], [0, 1]] // 3 * 3 格子
        data.forEach((item, index) => {
          prizes.push({
            x: axis[index][0],
            y: axis[index][1],
            borderRadius: 0,
            title: item.name,
            fonts: [{ text: item.name, top: '70%' }],
            imgs: [
              {
                src: require('../assets/cell.png'),
                activeSrc: require(`../assets/active.png`),
                width: '100%',
                height: '100%',
              },
              {
                src: item.img,
                height: '60%',
                top: '5px'
              }
            ]
          })
        })
        self.prizes = prizes;
        self.iniData = indexList.data;
        self.defaultTime = timeData.data;
        self.timeDownShow = true;
        self.isReady = true;
      }).catch(err => {
        console.log(err)
        self.$toast.clear();
      })
    },
    initTimeData () {
      var self = this;
      return new Promise(resolve => {
        this.axios({
          method: 'post',
          url: self.baseUrl + '/index/Index/gettime'
        }).then(function (res){
          resolve(res.data)
        })
      })
    },
    initIndexData () {
      var self = this;
      return new Promise(resolve => {
        this.axios({
          method: 'post',
          url: self.baseUrl + '/index/Index/getindex'
        }).then(function (res){
          resolve(res.data)
        })
      })
    },
    confirm () {
      var self = this;
      if (!this.username) {
        this.$toast.fail('请输入账号')
        return
      }
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      this.axios({
        method: 'post',
        url: self.baseUrl + '/index/Index/checkuser',
        data: {
          username: self.username
        }
      }).then(function (res){
        self.$toast.clear();
        if (res.data.code == 0) {
          self.userPopShow = false
          self.showLuckNum = true
          self.luckyNum = res.data.data.times
        } else {
          self.username = '';
          self.$toast.fail(res.data.msg)
          self.$refs.ipt.focus()
        }
      }).catch(err => {
        console.log(err)
        self.$toast.clear();
      })
    },
    queryResl () {
      var self = this;
      if (!this.queryname) {
        this.$toast.fail('请输入账号')
        return
      }
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      this.axios({
        method: 'post',
        url: self.baseUrl + '/index/Index/query',
        data: {
          username: self.queryname
        }
      }).then(function (res){
        self.$toast.clear();
        if (res.data.code == 0) {
          self.resl = res.data.data.list
        }
      }).catch(err => {
        console.log(err)
        self.$toast.clear();
      })
    },
    countDownS_cb() {
      this.hasTime = true
    },
    countDownE_cb() {
      this.hasTime = false
    },
    closeSearchPopup () {
      this.queryname = ''
      this.resl = []
    }
  }
}
</script>

<style>
*{
  box-sizing: border-box;
}
#app{
  text-align: center;
  height: 100%;
}
</style>
<style lang="stylus" scoped>
#mobile
  height 100%
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
.lucky-grid
  position relative
  // margin-top 12vw
  .lucky-img
    position absolute
    width 100%
    height 100%
    left 0
    top 0
    right 0
.neirong{
  padding: 15px 5px;
}
.x-close{
  background: url('~@/assets/x.png') no-repeat;
  background-size: 100%;
  width: 6.4vw;
  height: 6.4vw;
  position: absolute;
  right: 5px;
  top: 5px
}
.details-popup{
  background: #960102;
  border-radius: 10px 10px 0 0;
  .title{
    background-image: linear-gradient(#de731f, #ca2e0c);
    border-radius: 10px 10px 0 0;
    height: 11vw
  }
  .content{
    padding: 40px 25px;
    position: relative;
    >>> p{
      font-size: 3.47vw;
      line-height: 5.87vw;
      margin: 0;
      text-align: left;
      color: #e8aa25
    }
  }
  .tlt{
    left: 10px;
    top: 10px;
    background: url('~@/assets/tlt.png') no-repeat;
  }
  .trt{
    right: 10px;
    top: 10px;
    background: url('~@/assets/trt.png') no-repeat;
  }
  .tlb{
    left: 10px;
    bottom: 10px;
    background: url('~@/assets/tlb.png') no-repeat;
  }
  .trb{
    right: 10px;
    bottom: 10px;
    background: url('~@/assets/trb.png') no-repeat;
  }
}
.details-trispan{
  position: absolute;
  width: 8.53vw;
  height: 7.47vw;
  background-size: 100%!important;
}
.slide-float{
  position: fixed;
  top: 56%;
  right: 10px;
  width: 24vw;
  height: 24vw;
  background: url('~@/assets/hdxq.png') no-repeat;
  background-size: 100%;
}
.top-bar{
  background: #9b1f35;
  height: 15.4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
}
.top-bar img{
  height: 12.4vw;
}
.top-bar .btn-search{
  position: absolute;
  background: url('~@/assets/search.png') no-repeat 50% 50%;
  background-size: auto 90%;
  width: 8vw;
  height: 8vw;
  right: 3.2vw;
  top: 3.6vw;
}
.app-container{
  overflow: scroll;
  position: relative;
  // background: url('~@/assets/background.jpg') no-repeat #f84747;
  // background-size: 100% auto;
  background: #f84747;
  height 100%
  margin-top: 15.4vw;
  padding-bottom: 15.45vw;
}
.bottom-btn-bar{
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #9b1f35;
  height: 15.45vw;
  position: fixed;
  bottom: 0;
}
.bar-item{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.bar-icon{
  width: 8vw;
  height: 8vw;
}
.bar-item .txt{
  color: #fff;
  margin-top: 2px;
}
.bar-icon-home{
  background: url('~@/assets/home.png') no-repeat;
  background-size: auto 100%;
}
.bar-icon-app{
  background: url('~@/assets/app.png') no-repeat 1.87vw 0;
  background-size: auto 100%;
}
.bar-icon-gift{
  background: url('~@/assets/gift.png') no-repeat;
  background-size: 100% auto;
}
.bar-icon-service{
  background: url('~@/assets/service.png') no-repeat;
  background-size: auto 100%;
}
.time-down
  position relative
  margin-top: 4vw;
  color: #fff;
  font-size: 3vw;
  .sqa-l, .sqa-r
    position absolute
    width 45px
    height 42px
    background url('~@/assets/sqa.png') no-repeat
    background-size auto 42px
  .sqa-l
    left 10px
    top -10px
  .sqa-r
    right 10px
    top -10px
  >>> .time-clock
    display: flex;
    justify-content: center;
    align-items: center;
  >>> .title
    position relative
    margin-bottom: 10px
    font-size: 6vw
    font-weight: 550
    padding-left 40px
    &:before
      content ''
      position absolute
      background url('~@/assets/clock.png') no-repeat
      background-size 32px auto
      width 32px
      height 32px
      margin-left -40px
  >>> .num
    color #f8a421
    font-weight normal
.tc-item{
  display: flex;
  flex-direction: column;
  width: 12vw;
  height: 12vw;
  border: 3px solid #090101;
  border-radius: 3px;
  justify-content: center;
}
.tc-item + .tc-item{
  margin-left: 10px;
}
.tc-item .num{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: #1f1d1e;
}
.tc-item .txt{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: #434242;
}
.sc-popup{
  background: url('~@/assets/gglbg.png') no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 5vw 6vw 5vw 6vw;
}
.scratchCard{
  width: 100%!important;
  height: 30vw!important;
}
.scratchCard canvas{
  left: 0;
  bottom: 0;
  height: 30vw!important;
  width: 100%!important;
}
.scratchCard .result{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5vw;
  color: #f00;
}
.scratchCard .pic{
  display: none;
}
.account-container
  position: relative;
  margin-bottom: 5vw;
  margin-top: 5vw;
  display: flex
  flex-direction: column
  align-items: center
  img
    height 12vw
    margin 2vw 0 0
.ggl-container{
  margin-top: 12px;
  margin-bottom: 10vw;
  min-height: 96px;
}
.ggl-btn{
  background-image: linear-gradient(#f79605, #fef7c7 53%, #fcf97e);
  border: 0 none;
  color: #bb2c1c;
  border-radius: 5vw;
  margin-top: 10px;
  font-size: 4vw;
  padding: 2vw 10vw;
}
.count-static{
  font-size: 3.47vw;
  color: #fff;
  margin-top: 5px;
}
.title-bar{
  margin: 2vw 0 2vw;
  color: #fff;
  font-weight: normal;
  font-size: 4.5vw;
  letter-spacing: 3px;
}
.field__value-screen
  display flex
  flex-direction column
  width 75%
.field__control{
  border: 0 none;
  background: #fff;
  width: 40vw;
  height: 5.8vw;
  font-size: 2.4vw;
  padding: 0 5px;
  margin-top: 7px;
  color: #333;
  border-radius: 5px;
}
.popup-user-block
  background-color #9b1f35
  border-radius 10px
input.field__control::placeholder{
  text-indent: 1px;
}
.field__control-screen{
  border: 0 none
  width 100%
  height 5.8vw
  padding 0 5px
}
.button--info{
  background-image: linear-gradient(#f79605, #fef7c7 53%, #fcf97e);
  border: 0 none;
  color: #bb2c1c;
  width: 26vw;
  height: 5.5vw;
  border-radius: 3.6vw;
  margin-top: 12px;
}
.button--info-screen{
  background-image: linear-gradient(#f79605, #fef7c7 53%, #fcf97e);
  border: 0 none;
  color: #bb2c1c;
  width: 100%;
  height: 5.5vw;
  border-radius: 0.6vw;
  margin-top: 12px;
}
.popup-search-block{
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
  >>> .van-popup__close-icon--top-right {
    top: 2vw;
    right: 2vw;
  }
}
.block--title{
  background-image: linear-gradient(#dc722d, #cc2e1b);
  height: 40px;
  display: flex;
  color: #fff;
  font-size: 14px;
  padding-left: 10px;
  line-height: 40px;
}
.mcain-content{
  background: #94040c;
  flex: 1;
  padding: 20px 15px;
}
.search-content{
  display: flex;
  color: #fff;
  font-size: 3.2vw;
}
.x-t{
  min-width: 16vw;
}
.s-c-i{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.s-c-i .field__control{
  margin-top: 0;
  width: 100%;
}
.s-c-b .button--info{
  margin-top: 0;
  width: 18vw;
}
.rc-main{
  height: 44vw;
  overflow-y: scroll;
}
.rc-head, .rc-body{
  display: flex;
  font-size: 3.47vw;
  color: #fff;
}
.rc-head li, .rc-body li{
  flex: 1;
  height: 7.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rc-head li{
  margin-top: 10px;
  border: 1px solid #a5050f;
}
.van-overlay{
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.main-content{
  display flex
  flex-direction column
  justify-content space-between
}
.static-box
  width 70%
  background url('~@/assets/xxk.png') no-repeat
  background-size 100% 100%
  margin 15px auto
  padding 15px 0 20px
  h3
    font-size 16px
    color #fff
    margin 0
  p
    color #fff
    font-size 12px
    margin 10px 0 0
.copyright
  font-size .48vw
  margin 4.5vw 0
  color #fff
.lucky-btn
  position: absolute;
  left: 24vw
  top: 18vw
  width: 52vw
  height: 52vw
  background url('~@/assets/buttons-t.png') no-repeat 50% 50%
  background-size contain
  font-size 0
  display flex
  align-items: flex-end
  z-index: 1040
  .lcy-btn-inner
    background-image url('~@/assets/buttons-b.png')
    background-repeat no-repeat
    background-size 100% auto
    width 100%
    height 18vw
.animation
  .lcy-btn-inner
    background-image url('~@/assets/buttons-m.gif')
.popup-coupons-block
  background-color transparent
  background url('~@/assets/coupons.png') no-repeat 50% 50%
  background-size contain
  .coupons-word
    margin: 52vw auto 0
    padding: 0 18vw
    font-size 3.47vw
  >>> .van-popup__close-icon--top-right
    top: 2vw;
    right: 2vw;
</style>
